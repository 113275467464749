



















import { Component } from 'vue-property-decorator'
import Vue from 'vue'

import AddAssigneeManagementWidgetBtn from '~/components/dashboard/widget/collaborate/assigneeManagementWidget/AddWidgetBtn.vue'
import AddCalendarViewWidgetBtn from '~/components/dashboard/widget/collaborate/calendarViewWidget/AddWidgetBtn.vue'
import AddDailyTaskListWidgetBtn from '~/components/dashboard/widget/collaborate/dailyTaskListWidget/AddWidgetBtn.vue'
import AddTopicListWidgetBtn from '~/components/dashboard/widget/collaborate/topicListWidget/AddWidgetBtn.vue'

@Component({
  components: {
    AddAssigneeManagementWidgetBtn,
    AddCalendarViewWidgetBtn,
    AddDailyTaskListWidgetBtn,
    AddTopicListWidgetBtn,
  },
  name: 'dashboard-add-widget-dial',
})
export default class AddWidgetDial extends Vue {
  public fab: boolean = false
}
