










import { Component, Provide, Watch } from 'vue-property-decorator'
import Vue from 'vue'

import apolloClient from '~/apollo'

import AddWidgetDial from '~/components/dashboard/AddWidgetDial.vue'
import Preload from '~/components/common/Preload.vue'

import widgetConfigCreated from '~/apollo/subscriptions/widgetConfigCreated.gql'
import widgetConfigDeleted from '~/apollo/subscriptions/widgetConfigDeleted.gql'
import widgetConfigUpdated from '~/apollo/subscriptions/widgetConfigUpdated.gql'

import PlaceHolderContainer from '~/components/dashboard/PlaceHolderContainer.vue'
import WidgetContainer from '~/components/dashboard/WidgetContainer.vue'
import WithoutWidget from '~/components/dashboard/WithoutWidget.vue'

import { eventSourceHandler } from '~/helpers'

@Component({
  components: {
    AddWidgetDial,
    PlaceHolderContainer,
    Preload,
    WidgetContainer,
    WithoutWidget,
  },
  name: 'dashboard-content',
})
export default class Content extends Vue {
  @Provide() public containerClass = 'widget-config-draggable-container'
  @Provide() public dragClass = 'widget-config-draggable'
  @Provide() public handleClass = 'widget-config_drag-handle'

  public dataLoading = false
  public createdObservable: ZenObservable.Subscription
  public deletedObservable: ZenObservable.Subscription
  public updatedObservable: ZenObservable.Subscription
  public $refs: any

  get currentAccount() {
    return this.$store.myGetters['accounts/account/currentAccount']
  }

  get isMobile() {
    return this.$store.myGetters.getIsMobile
  }

  get widgetData() {
    return this.$store.myGetters.getWidgetConfigs
  }

  @Watch('isMobile')
  public handleWidgetContainerCompleted() {
    this.$refs.draggable && this.$refs.draggable.initialization()
  }

  public async initialization() {
    this.createdObservable = await apolloClient
      .subscribe({
        query: widgetConfigCreated,
        variables: {
          userId: this.currentAccount.id,
        },
      })
      .subscribe({
        next: (result: any) => {
          const { eventSourceId, widgetConfigs } = result.data.widgetConfigCreated
          !eventSourceHandler.checkIsSameSource(eventSourceId) &&
            this.$store.myActions.saveWidgetConfigsFromPayload({
              entities: widgetConfigs,
            })
        },
      })
    this.deletedObservable = await apolloClient
      .subscribe({
        query: widgetConfigDeleted,
        variables: {
          userId: this.currentAccount.id,
        },
      })
      .subscribe({
        next: (result: any) => {
          const { eventSourceId, widgetConfig } = result.data.widgetConfigDeleted
          !eventSourceHandler.checkIsSameSource(eventSourceId) &&
            this.$store.myActions.removeWidgetConfigsFromPayload({
              entity: widgetConfig,
            })
        },
      })
    this.updatedObservable = await apolloClient
      .subscribe({
        query: widgetConfigUpdated,
        variables: {
          userId: this.currentAccount.id,
        },
      })
      .subscribe({
        next: (result: any) => {
          const { eventSourceId, widgetConfig } = result.data.widgetConfigUpdated
          !eventSourceHandler.checkIsSameSource(eventSourceId) &&
            this.$store.myActions.saveWidgetConfigsFromPayload({
              entities: [widgetConfig],
            })
        },
      })
  }

  public async created() {
    this.dataLoading = true
    await this.$store.myActions.fetchWidgetConfig()
    this.initialization()
    this.dataLoading = false
  }

  public beforeDestroy() {
    this.createdObservable.unsubscribe()
    this.deletedObservable.unsubscribe()
    this.updatedObservable.unsubscribe()
  }
}
