








import { Component } from 'vue-property-decorator'
import Vue from 'vue'

import Breadcrumbs from '~/components/common/Breadcrumbs.vue'

@Component({
  components: {
    Breadcrumbs,
  },
  name: 'dashboard-header',
})
export default class Header extends Vue {
  public breadcrumbsData: object = [
    {
      text: 'Dashboard',
      disabled: true,
    },
  ]
}
